@import 'tools/styles/flex';
@import 'tools/styles/grid';
@import 'tools/styles/helpers';
@import 'tools/styles/adaptive';

.lineupContainer {
    .lineup {
        height: fit-content;

        @include borderRadius(25px);
        background: #F9F9FA;

        padding: 20px 26px 20px 26px;

        .content {
            @include dFlex();
            @include flexColumn();

            gap: 16px;

            .title {
                font-size: 12px;
                line-height: 14px;
                font-weight: 500;
            }

            .players {
                @include dFlex();
                @include flexRow();

                gap: 5px;

                .player {
                    width: 118px;
                }
            }
        }
    }
}

@include applyResponsive320() {
    .lineupContainer {
        @include dFlex();

        width: 100%;
        overflow: auto;

        .lineup {
            width: 100%;
            min-width: 660px;
            max-width: 660px;

            margin: 0 17px 20px 17px;
        }
    }
}

@include applyResponsive768() {
    .lineupContainer {
        .lineup {
            margin: 0;
        }
    }
}

@include applyResponsive1280() {
    .lineupContainer {
        @include justifyContentCenter();
        margin-left: 90px;
    }
}

@include applyResponsive1440() {
    .lineupContainer {
        margin-left: 67px;
    }
}
@import 'styles/helpers';
@import 'tools/styles/flex';
@import 'tools/styles/grid';
@import 'tools/styles/adaptive';

.team {
    @include dFlex();
    @include flexColumn();
    @include alignItemsCenter();
    @include removeLinkUnderline();

    font-size: 15px;
    line-height: 30px;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    color: #000000;

    gap: 5px;

    .teamImage {}
}

@include applyResponsive320() {
    .team {
        .teamImage {
            width: auto;
            height: auto;
            max-height: 80px;
        }
    }
}

@include applyResponsive1280() {
    .team {
        .teamImage {
            width: auto;
            height: auto;
            max-height: 100px;
        }
    }
}
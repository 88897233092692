@import 'tools/styles/flex';
@import 'tools/styles/adaptive';

.player {
    @include dFlex();
    @include flexRow();
    @include alignItemsCenter();

    font-style: normal;
    font-weight: 500;
    color: #000000;
}

@include applyResponsive320() {
    .player {
        font-size: 13px;
        line-height: 14px;
    }
}

@include applyResponsive1280() {
    .player {
        font-size: 13px;
        line-height: 14px;
    }
}

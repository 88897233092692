@import 'tools/styles/helpers';
@import 'tools/styles/adaptive';

.doc {
    .name {
        @include removeLinkUnderline();

        font-style: normal;
        font-weight: 400;
        color: #3173FF;
        cursor: pointer;
    }
    .size {
        font-style: normal;
        font-weight: 400;
        color: #7C7C7C;
    }
}

@include applyResponsive320() {
    .doc {
        .name {
            font-size: 12px;
            line-height: 14px;

            margin-bottom: 2px;
        }
        .size {
            font-size: 9px;
            line-height: 11px;
        }
    }
}

@include applyResponsive1280() {
    .doc {
        .name {
            font-size: 12px;
            line-height: 14px;

            margin-bottom: 2px;
        }
        .size {
            font-size: 9px;
            line-height: 11px;
        }
    }
}

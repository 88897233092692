.top {
    padding: 18px 0 9px 0;

    .item {
        font-style: normal;
        font-weight: 500;
        color: #3D3D3D;
        text-align: start;

        font-size: 11px;
        line-height: 12px;
    }
}

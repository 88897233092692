@import 'tools/styles/grid';
@import 'tools/styles/flex';
@import 'tools/styles/helpers';
@import 'tools/styles/adaptive';

.notFound {
    .left {
        .leftLogo {

        }
    }
    .main {
        position: relative;

        .notFoundImage {
            position: absolute;

            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            width: 100%;
            height: 100%;
        }
        .mainButton {
            @include dFlex();
            @include flexRow();
            @include justifyContentCenter();
            @include alignItemsCenter();
            @include removeLinkUnderline();

            position: absolute;

            background: #494949;

            font-style: normal;
            font-weight: 700;
            color: #FFFFFF;
            cursor: pointer;
        }
    }
    .right {
        .rightText {
            font-style: normal;
            font-weight: 400;
            color: #000000;
        }
    }
}

@include applyResponsive320() {
    .notFound {
        @include dFlex();
        @include flexColumnReverse();
        @include alignItemsCenter();

        margin-top: 0;
        margin-bottom: 42px;

        .left {
            display: none;

            .leftLogo {

            }
        }
        .main {
            width: 271px;
            height: 204px;

            padding: 0 25px 0 25px;
            margin-bottom: 53px;

            .notFoundImage {

            }
            .mainButton {
                @include borderRadius(5px);

                top: 100%;
                left: calc(50% - 150px / 2);

                width: 150px;
                height: 53px;

                font-size: 9px;
                line-height: 11px;
            }
        }
        .right {
            @include dFlex();
            @include flexRow();
            @include justifyContentCenter();
            @include alignItemsCenter();

            .rightText {
                font-size: 15px;
                line-height: 177.69%;
            }
        }
    }
}

@include applyResponsive1280() {
    .notFound {
        @include dGrid();
        @include gridColumns(1fr 1fr 1fr);

        margin-top: -23px;
        margin-bottom: 0;

        .left {
            @include dFlex();
            @include flexRow();
            @include justifyContentEnd();
            @include alignItemsCenter();

            .leftLogo {
                width: 100px;
                height: 34px;
            }
        }
        .main {
            width: 658px;
            height: 494px;

            padding: 0 33px 0 33px;
            margin-bottom: 0;

            .notFoundImage {

            }
            .mainButton {
                @include borderRadius(5px);

                top: auto;
                bottom: 15px;
                left: calc(50% - 150px / 2);

                width: 150px;
                height: 53px;

                font-size: 9px;
                line-height: 11px;
            }
        }
        .right {
            @include dFlex();
            @include flexRow();
            @include justifyContentStart();
            @include alignItemsCenter();

            .rightText {
                font-size: 15px;
                line-height: 177.69%;
            }
        }
    }
}

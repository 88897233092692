@import 'tools/styles/flex';
@import 'tools/styles/helpers';
@import 'tools/styles/adaptive';

.match {
    @include dFlex();
    @include flexColumn();
    @include alignItemsCenter();

    background: #FFFFFF;

    .date {
        font-style: normal;
        font-weight: 400;
        color: #3173FF;
    }
    .vs {
        @include dFlex();
        @include flexRow();
        @include justifyContentBetween();
        @include alignItemsCenter();

        .teamImage {

        }
        .vsImage {

        }
    }
    .time {
        font-style: normal;
        font-weight: 400;
    }
}

@include applyResponsive320() {
    .match {
        @include borderRadius(10px);

        width: 100%;

        padding: 21px 33px 25px 32px;

        .date {
            font-size: 13px;
            line-height: 14px;

            margin-bottom: 13px;
        }
        .vs {
            width: 100%;

            margin-bottom: 16px;

            .teamImage {
                width: 50px;
                height: 50px;
            }
            .vsImage {
                width: 34px;
                height: 46px
            }
        }
        .time {
            color: #000000;
            font-size: 13px;
            line-height: 14px;
        }
    }
}

@include applyResponsive1280() {
    .match {
        @include borderRadius(10px);

        width: 100%;

        padding: 21px 33px 25px 32px;

        .date {
            font-size: 13px;
            line-height: 14px;

            margin-bottom: 13px;
        }
        .vs {
            width: 100%;

            margin-bottom: 16px;

            .teamImage {
                width: 50px;
                height: 50px;
            }
            .vsImage {
                width: 34px;
                height: 46px
            }
        }
        .time {
            color: #7A7A7A;
            font-size: 13px;
            line-height: 14px;
        }
    }
}

@import 'tools/styles/flex';
@import 'tools/styles/helpers';
@import 'tools/styles/adaptive';

.report {
    @include dFlex();
    @include flexRow();

    background: #FFFFFF;

    .image {

    }
    .content {
        @include dFlex();
        @include flexColumn();
        @include justifyContentBetween();

        .title {
            font-style: normal;
            font-weight: 500;
            color: #000000;
        }
        .moreLink {
            @include dFlex();
            @include flexRow();
            @include alignItemsCenter();

            font-style: normal;
            font-weight: 500;
            color: #000000;
            cursor: pointer;

            .arrowImage {

            }
        }
    }
}

@include applyResponsive320() {
    .report {
        @include borderRadius(10px);

        .image {
            @include borderRadius(10px);

            width: 111px;
            min-width: 111px;
            height: 100px;
            min-height: 100px;
        }
        .content {
            padding: 18px 7px 12px 19px;

            .title {
                font-size: 11px;
                line-height: 13px;
            }
            .moreLink {
                font-size: 10px;
                line-height: 11px;

                .arrowImage {
                    width: 6px;
                    height: 6px;

                    margin-left: 4px;
                }
            }
        }
    }
}

@include applyResponsive1280() {
    .report {
        @include borderRadius(10px);

        .image {
            @include borderRadius(10px);

            width: 111px;
            min-width: 111px;
            height: 100px;
            min-height: 100px;
        }
        .content {
            padding: 18px 63px 12px 19px;

            .title {
                font-size: 13px;
                line-height: 14px;
            }
            .moreLink {
                font-size: 10px;
                line-height: 11px;

                .arrowImage {
                    width: 6px;
                    height: 6px;

                    margin-left: 4px;
                }
            }
        }
    }
}

@import 'tools/styles/flex';
@import 'tools/styles/helpers';

.tabs {
    @include dFlex();
    @include flexRow();
    @include hideScrollbar();

    overflow-x: auto;

    height: fit-content;

    .tab {
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }
    }
}

@import 'tools/styles/flex';
@import 'tools/styles/helpers';
@import 'tools/styles/adaptive';

.commonCard {
    background: #F9F9FA;

    cursor: pointer;

    .date {
        font-style: normal;
        font-weight: 400;
        color: #3173FF;
        text-align: center;
        text-decoration: underline;
    }
    .content {
        @include dFlex();
        @include flexRow();
        @include justifyContentBetween();
        @include alignItemsCenter();

        .teamImage {

        }
        .result {
            @include dFlex();
            @include flexRow();
            @include justifyContentBetween();
            @include alignItemsCenter();

            position: relative;

            .value {
                z-index: 1;

                font-style: normal;
                font-weight: 700;
                color: #020202;
            }
            .vsImage {
                position: absolute;
            }
        }
    }
    .time {
        font-style: normal;
        font-weight: 400;
        color: #020202;
        text-align: center;
    }
}

@include applyResponsive320() {
    .commonCard {
        @include borderRadius(10px);

        width: 286px;

        padding: 28px 39px 30px 39px;

        .date {
            font-size: 12px;
            line-height: 14px;

            margin-bottom: 7px;
        }
        .content {
            padding: 9px 0 17px 0;

            .teamImage {
                width: 47px;
                height: 47px;
            }
            .result {
                width: 73px;
                height: 100%;

                .value {
                    font-size: 23px;
                    line-height: 27px;
                }
                .vsImage {
                    top: -30px;
                    left: 10px;
                    right: 0;
                    bottom: 0;

                    width: 48px;
                    height: 64px;
                }
            }
        }
        .time {
            font-size: 10px;
            line-height: 12px;
        }
    }
}

@include applyResponsive1280() {
    .commonCard {
        @include borderRadius(10px);

        width: 417px;

        padding: 28px 49px 30px 49px;

        .date {
            font-size: 12px;
            line-height: 14px;

            margin-bottom: 7px;
        }
        .content {
            padding: 9px 0 17px 0;

            .teamImage {
                width: 72px;
                height: 72px;
            }
            .result {
                width: 95px;
                height: 100%;

                .value {
                    font-size: 30px;
                    line-height: 35px;
                }
                .vsImage {
                    top: -30px;
                    left: 10px;
                    right: 0;
                    bottom: 0;

                    width: 73px;
                    height: 98px;
                }
            }
        }
        .time {
            font-size: 10px;
            line-height: 12px;
        }
    }
}

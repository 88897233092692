@import 'styles/helpers';
@import 'tools/styles/flex';
@import 'tools/styles/grid';
@import 'tools/styles/animations';
@import 'tools/styles/adaptive';

.players {
    .tableContainer {
        .table {
            font-size: 12px;
            line-height: 14px;
            font-weight: 500;

            @include borderRadius(25px);
            background: #F9F9FA;

            .row {
                @include dGrid();
                @include gridRows(1fr);

                .column {
                    @include dFlex();
                    margin-right: 5px;
                }
            }

            .rowTop {}

            .magicTop {}

            .list {
                @include dFlex();
                @include flexColumn();

                .player {
                    background: transparent;
                    @include borderRadius(10px);

                    &:nth-child(odd) {
                        background: #FFFFFF;
                    }
                }
            }

            .magicBottom {}
        }
    }

    .moreButtonContainer {
        .moreButton {
            font-style: normal;
            font-weight: 700;
        }
    }
}

@include applyResponsive320() {
    .players {
        width: 100%;

        .tableContainer {
            width: 100%;
            overflow-x: auto;

            .table {
                min-width: 640px;
                padding: 5px 24px 10px 24px;

                .row {
                    @include gridColumns(repeat(8, 1fr));
                    padding: 24px 6px 22px 6px;

                    .column {
                        @include justifyContentCenter();
                    }
                }

                .rowTop {
                    padding: 30px 6px;
                }

                .magicTop {
                    display: none !important;
                }

                .magicBottom {
                    display: none !important;
                }
            }
        }

        .moreButtonContainer {
            @include dFlex();
            @include flexRow();
            @include justifyContentCenter();

            padding: 40px 0 35px 0;

            .moreButton {
                width: 122px;
                height: 40px;

                font-size: 9px;
                line-height: 11px;
            }
        }
    }
}

@include applyResponsive768() {
    .players {
        .tableContainer {
            .table {
                .row {
                    @include gridColumns(repeat(2, 2fr) repeat(6, 1fr));
                    padding: 20px 32px;

                    .column {
                        @include justifyContentStart();
                    }
                }

                .rowTop {
                    padding: 25px 32px;
                }
            }
        }
    }
}

@include applyResponsive1280() {
    .players {
        position: relative;

        .tableContainer {
            .table {
                padding: 5px 30px 30px 44px;

                .row {
                    @include gridColumns(255px 315px repeat(6, 1fr));
                    padding: 20px 32px;
                }

                .rowTop {
                    padding-right: 61px;
                }

                .magicTop {
                    display: block !important;

                    position: absolute;
                    left: 15px;
                    right: 35px;
                    top: 38px;

                    height: 30px;

                    background: #F9F9FA;
                    filter: blur(5px);
                }

                .list {
                    @include blueScrollBar();

                    padding: 10px 25px 18px 0;
                }

                .magicBottom {
                    display: block !important;

                    position: absolute;
                    left: 15px;
                    right: 35px;
                    bottom: 10px;

                    height: 38px;

                    background: #F9F9FA;
                    filter: blur(7px);
                }
            }
        }
    }
}
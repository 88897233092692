@import 'tools/styles/flex';
@import 'tools/styles/adaptive';

.footer {
    width: 100%;

    .topMobile {
        .logos {
            .topLogo {

            }
            .bottomLogo {

            }
        }
        .text {

        }
    }
    .top {
        .leftLogo {

        }
        .middle {
            .text {
                font-style: normal;
                font-weight: 400;
                color: #A4A4A4;
            }
            .files {
                .agreement {
                    display: block;

                    font-size: 10px;
                    line-height: 177.69%;
                    color: #000000;

                    margin-right: 69px;
                }
                .personalData {
                    display: block;

                    font-size: 10px;
                    line-height: 177.69%;
                    color: #000000;

                    margin-right: 84px;
                }
                .sites {
                    display: block;

                    font-size: 10px;
                    line-height: 177.69%;
                    color: #000000;
                }
            }
        }
        .rightLogo {
            width: 101px;
            height: 34px;

            margin-left: 31px;
        }
    }
    .bottom {
        @include dFlex();

        .copyright {
            font-style: normal;
            font-weight: 400;
            color: #5C5C5C;
        }
        .networks {
            width: fit-content;
        }
    }
}

@include applyResponsive320() {
    .footer {
        padding: 25px 35px 25px 35px;

        .topMobile {
            @include dFlex();
            @include flexRow();
            @include justifyContentBetween();

            margin-bottom: 26px;

            .logos {
                margin-right: 16px;

                .topLogo {
                    width: 94px;
                    height: 24px;

                    margin-bottom: 24px;
                }
                .bottomLogo {
                    width: 77px;
                    height: 26px;
                }
            }
            .text {
                font-style: normal;
                font-weight: 400;
                color: #8A8A8A;

                font-size: 6px;
                line-height: 177.69%;
            }
        }
        .top {
            margin-bottom: 34px;

            .leftLogo {
                display: none;
            }
            .middle {
                .text {
                    display: none;
                }
                .files {
                    .agreement {
                        font-size: 10px;
                        line-height: 177.69%;

                        margin-bottom: 14px;
                    }
                    .personalData {
                        font-size: 10px;
                        line-height: 177.69%;

                        margin-bottom: 14px;
                    }
                    .sites {
                        font-size: 10px;
                        line-height: 177.69%;
                    }
                }
            }
            .rightLogo {
                display: none;
            }
        }
        .bottom {
            @include flexColumnReverse();
            @include alignItemsCenter();

            .copyright {
                font-size: 8px;
                line-height: 177.69%;

                margin-right: 0;
            }
            .networks {
                width: fit-content;

                margin-bottom: 28px;
            }
        }
    }
}

@include applyResponsive768() {
    .footer {
        padding: 25px 75px 25px 75px;

        .topMobile {
            display: none !important;

            .logos {
                .topLogo {

                }
                .bottomLogo {

                }
            }
            .text {

            }
        }
        .top {
            @include dFlex();
            @include flexRow();

            margin-bottom: 0;

            .leftLogo {
                display: block;

                width: 94px;
                height: 24px;

                margin-right: 31px;
            }
            .middle {
                margin-bottom: 38px;

                .text {
                    display: block;

                    font-size: 6px;
                    line-height: 177.69%;

                    margin-bottom: 33px;
                }
                .files {
                    @include dFlex();
                    @include flexRow();

                    .agreement {
                        font-size: 10px;
                        line-height: 177.69%;

                        margin-right: 69px;
                        margin-bottom: 0;
                    }
                    .personalData {
                        font-size: 10px;
                        line-height: 177.69%;

                        margin-right: 84px;
                        margin-bottom: 0;
                    }
                    .sites {
                        font-size: 10px;
                        line-height: 177.69%;
                    }
                }
            }
            .rightLogo {
                display: block;

                width: 101px;
                height: 34px;

                margin-left: 31px;
            }
        }
        .bottom {
            @include flexRow();
            @include justifyContentEnd();
            @include alignItemsCenter();

            .copyright {
                font-size: 8px;
                line-height: 177.69%;

                margin-right: 27px;
            }
            .networks {
                width: fit-content;

                margin-bottom: 0;
            }
        }
    }
}

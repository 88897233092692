@import 'tools/styles/flex';
@import 'tools/styles/grid';
@import 'tools/styles/helpers';
@import 'tools/styles/adaptive';

.player {
    color: #000000;
    @include removeLinkUnderline();

    .row {
        .column {
            @include dFlex();
            @include alignItemsCenter();

            .countryImage {
                width: 19px;
                height: 13px;

                margin-right: 13px;
            }

            .playerImage {
                width: 37px;
                height: 37px;

                background-position: 50% 0;

                @include borderRadius(25px);
            }

            .teamImage {
                width: 40px;
                height: 40px;
            }
        }

        .firstColumn {
            @include dFlex();
        }
    }
}

@include applyResponsive320() {
    .player {
        .row {
            .column {
                @include justifyContentCenter();

                .countryImage {
                    display: none !important;
                }

                .playerImage {
                    margin-bottom: 9px;
                }
            }

            .firstColumn {
                @include flexColumn();
            }
        }
    }
}

@include applyResponsive768() {
    .player {
        .row {
            .column {
                @include justifyContentStart();

                .playerImage {
                    margin-bottom: 0;
                    margin-right: 12px;
                }
            }

            .firstColumn {
                @include flexRow();
            }
        }
    }
}

@include applyResponsive1280() {
    .player {
        .row {
            .column {
                .countryImage {
                    display: block !important;
                }
            }
        }
    }
}
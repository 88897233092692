@import 'tools/styles/flex';
@import 'tools/styles/helpers';
@import 'tools/styles/adaptive';

.match {
    @include dFlex();
    @include flexColumn();
    @include justifyContentBetween();
    @include alignItemsCenter();

    min-height: 196px;

    @include borderRadius(10px);
    background: #F9F9FA;

    .date {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        color: #3173FF;
    }

    .basic {
        @include dFlex();
        @include flexRow();
        @include justifyContentBetween();
        @include alignItemsCenter();

        position: relative;

        .teamImage {
            z-index: 2;
        }

        .score {
            @include dFlex();
            @include flexRow();

            font-style: normal;
            font-weight: 700;
            color: #020202;

            gap: 50px;
            padding: 0 25px;

            z-index: 2;
        }

        .vsImage {
            position: absolute;
            z-index: 1;
        }
    }

    .time {
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        color: #020202;
    }
}

@include applyResponsive320 {
    .match {
        padding: 28px 20px 39px 20px;

        .basic {
            .teamImage {
                width: 55px;
                min-height: 55px;
            }

            .score {
                font-size: 23px;
                line-height: 26px;
            }

            .vsImage {
                right: 98px;

                width: 48px;
                height: 64px;
            }
        }
    }
}

@include applyResponsive768() {
    .match {
        padding: 28px 28px 39px 28px;

        .basic {
            .score {
                font-size: 30px;
                line-height: 35px;
            }

            .teamImage {
                width: 68px;
                max-height: 68px;
            }

            .vsImage {
                right: 112px;

                width: 56px;
                height: 72px;
            }
        }
    }
}

@include applyResponsive1280() {
    .match {
        padding: 28px 29px 30px 29px;

        .basic {
            .teamImage {
                width: 82px;
                min-height: 82px;
            }

            .vsImage {
                right: 120px;

                width: 73px;
                height: 98px;
            }
        }
    }
}

@include applyResponsive1440() {
    .match {
        padding: 28px 49px 30px 49px;
    }
}
@font-face {
    font-weight: 800;
    font-family: 'Raleway';
    src: url(../fonts/Raleway-ExtraBold.woff2),
        url(../fonts/Raleway-ExtraBold.woff);
    font-display: swap;
    font-style: normal;
}

@mixin fontRaleway800() {
    font-family:
        'Raleway',
        sans-serif;
    font-weight: 800;
}

@font-face {
    font-weight: 700;
    font-family: 'Roboto Condensed';
    src: url(../fonts/RobotoCondensed-Bold.woff2),
        url(../fonts/RobotoCondensed-Bold.woff);
    font-display: swap;
    font-style: normal;
}

@mixin fontRobotoCondensed700() {
    font-family:
        'Roboto Condensed',
        sans-serif;
    font-weight: 700;
}

@font-face {
    font-weight: 500;
    font-family: 'Raleway';
    src: url(../fonts/Raleway-Medium.woff2),
        url(../fonts/Raleway-Medium.woff);
    font-display: swap;
    font-style: normal;
}

@mixin fontRaleway500() {
    font-family:
        'Raleway',
        sans-serif;
    font-weight: 500;
}

@font-face {
    font-weight: 400;
    font-family: 'Raleway';
    src: url(../fonts/Raleway-Regular.woff2),
        url(../fonts/Raleway-Regular.woff);
    font-display: swap;
    font-style: normal;
}

@mixin fontRaleway400() {
    font-family:
        'Raleway',
        sans-serif;
    font-weight: 400;
}
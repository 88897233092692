@import 'tools/styles/flex';
@import 'tools/styles/helpers';
@import 'tools/styles/adaptive';

.avatar {
    position: relative;

    background: #F9F9FA;
    @include borderRadius(25px);

    .teamImg {
        position: absolute;

        top: 16px;
        right: 32px;

        width: 39px;
        height: 42px;

        z-index: 2;
    }

    .avatarImg {
        position: absolute;
        bottom: 0;

        max-width: 100%;
        max-height: 100%;

        padding: 10px 10px 0 10px;

        z-index: 1;
    }

    .backgroundLogo {
        @include dFlex();
        @include flexRow();
        @include justifyContentCenter();
        @include alignItemsCenter();

        width: 100%;
        height: 100%;

        padding: 33px 21px 125px 21px;
    }
}
@import 'tools/styles/grid';
@import 'tools/styles/helpers';
@import 'tools/styles/adaptive';

.stats {
    @include borderRadius(25px);
    background: #F9F9FA;

    .content {
        @include dGrid();

        .card {
            font-weight: 500;

            background: #FFFFFF;
            @include borderRadius(10px);

            padding: 14px 50px 15px 25px;

            .value {
                font-size: 16px;
                line-height: 18px;
                color: #040404;

                margin-bottom: 4px;
            }

            .characteristic {
                font-size: 9px;
                line-height: 10px;
                color: #AFAFAF;
            }
        }
    }
}

@include applyResponsive320() {
    .stats {
        padding: 23px 18px;

        .content {
            @include gridColumns(1fr 1fr);
            @include gridRows(1fr 1fr 1fr);

            gap: 10px 8px;
        }
    }
}

@include applyResponsive1280() {
    .stats {
        padding: 22px 26px;

        .content {
            @include gridColumns(1fr 1fr 1fr);
            @include gridRows(1fr 1fr);

            gap: 8px;
        }
    }
}
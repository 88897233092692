@import 'tools/styles/flex';
@import 'tools/styles/helpers';

.cover {
    @include backgroundSize(cover);

    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.contain {

}

.loading {
    @include dFlex();
    @include flexRow();
    @include justifyContentCenter();
    @include alignItemsCenter();
}

@import 'tools/styles/flex';
@import 'tools/styles/helpers';
@import 'tools/styles/adaptive';

.mapsCard {
    @include dFlex();
    @include flexRow();

    background: #F9F9FA;
}

@include applyResponsive320() {
    .mapsCard {
        @include borderRadius(10px);

        gap: 5px;

        padding: 18px 33px 14px 26px;
    }
}

@include applyResponsive1280() {
    .mapsCard {
        @include borderRadius(10px);

        gap: 5px;

        padding: 18px 33px 14px 26px;
    }
}

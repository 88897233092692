@import 'styles/helpers';
@import 'tools/styles/flex';
@import 'tools/styles/helpers';
@import 'tools/styles/adaptive';

.playersList {
    background: #FFFFFF;

    .title {
        font-style: normal;
        font-weight: 700;
        color: #000000;
    }
    .list {
        @include dFlex();

        width: fit-content;
    }
}

@include applyResponsive320() {
    .playersList {
        .title {
            font-size: 19px;
            line-height: 22px;

            margin-bottom: 20px;
        }
        .list {
            @include flexRow();
            @include borderRadius(15px);
            @include hideScrollbar();
            @include overflowX(auto);

            max-width: 100%;

            gap: 7px;

            background: #F9F9FA;
        }
    }
}

@include applyResponsive1280() {
    .playersList {
        .title {
            font-size: 19px;
            line-height: 22px;

            margin-bottom: 20px;
        }
        .list {
            @include flexColumn();
            @include alignItemsCenter();
            @include borderRadius(10px);
            @include blueScrollBar();
            @include showScrollbar();
            @include overflowX(hidden);

            gap: 7px;

            background: #EDEDED;

            padding: 12px 11px 12px 11px;
        }
    }
}

@import 'styles/helpers';
@import 'tools/styles/flex';
@import 'tools/styles/grid';
@import 'tools/styles/adaptive';

.teams {
    @include dFlex();
    @include flexColumn();

    position: relative;

    .top {
        font-weight: 500;

        .title {
            font-size: 25px;
            line-height: 40px;
        }

        .dateSeason {
            font-size: 15px;
            line-height: 30px;
            color: #A1A1A1;
        }
    }

    .magicTop {
        position: absolute;
        left: -20px;
        right: 15px;
        top: 68px;

        height: 38px;

        background: #FFFFFF;
        filter: blur(7px);
    }

    .list {
        @include dGrid();
        @include gridRows(1fr);
        @include blueScrollBar();
    }

    .magicBottom {
        position: absolute;
        left: -20px;
        right: 15px;
        bottom: -20px;

        height: 38px;

        background: #FFFFFF;
        filter: blur(7px);
    }
}

@include applyResponsive320() {
    .teams {
        @include alignItemsCenter();
        padding: 44px 15px 20px 38px;

        .top {
            margin-right: 140px;
            margin-bottom: 17px;
        }

        .magicTop {
            display: none !important;
        }

        .list {
            @include gridColumns(repeat(2, 1fr));

            width: fit-content;

            gap: 35px 45px;
            padding: 15px 30px 15px 0;
        }

        .magicBottom {
            display: none !important;
        }
    }
}

@include applyResponsive768() {
    .teams {
        .top {
            margin-right: 550px;
        }

        .magicTop {}

        .list {
            @include gridColumns(repeat(5, 1fr));
        }

        .magicBottom {}
    }
}

@include applyResponsive1280() {
    .teams {
        @include alignItemsStart();

        .magicTop {}

        .list {
            @include gridColumns(repeat(8, 1fr));
            gap: 30px;
        }

        .magicBottom {}
    }
}

@include applyResponsive1440() {
    .teams {
        padding: 44px 0;

        .magicTop {}

        .list {
            @include gridColumns(repeat(10, 1fr));
        }

        .magicBottom {}
    }
}
@import 'tools/styles/grid';
@import 'tools/styles/helpers';
@import 'tools/styles/adaptive';

.stats {
    max-width: 551px;

    @include borderRadius(25px);
    background: #F9F9FA;

    .content {
        @include dGrid();
        @include gridRows(1fr 1fr);

        gap: 8px;

        .card {
            font-weight: 500;

            background: #FFFFFF;
            @include borderRadius(10px);

            padding: 14px 15px 15px 16px;

            .value {
                font-size: 16px;
                line-height: 18px;
                color: #040404;

                margin-bottom: 4px;
            }

            .center {
                text-align: center;
            }

            .characteristic {
                font-size: 9px;
                line-height: 10px;
                white-space: nowrap;
                color: #AFAFAF;
            }
        }
    }
}


@include applyResponsive320 {
    .stats {
        padding: 23px 18px 23px 17px;
        margin: 0 22px 13px 17px;

        .content {
            @include gridColumns(repeat(2, 1fr));

            .card {
                .center {
                    text-align: start;
                }
            }
        }
    }
}

@include applyResponsive768 {
    .stats {
        margin: 0;
        margin-bottom: 18px;

        .content {
            @include gridColumns(repeat(3, 1fr));
        }
    }
}

@include applyResponsive1280 {
    .stats {
        padding: 22px 26px 22px 26px;
        margin-bottom: 11px;
    }
}
.team {
    padding: 5px 0 6px 0;

    .order {
        font-style: normal;
        font-weight: 700;
        text-align: start;
        color: #3D3D3D;

        font-size: 15px;
        line-height: 12px;

        padding-left: 7px;
    }
    .logo {
        .logoLink {
            width: fit-content;
            cursor: pointer;

            .logoImage {
                width: 40px;
                height: 40px;
            }
        }
    }
    .points {
        font-style: normal;
        font-weight: 700;
        text-align: start;
        color: #3D3D3D;

        font-size: 13px;
        line-height: 12px;

        padding-left: 5px;
    }
    .winsTotal {
        font-style: normal;
        font-weight: 700;
        text-align: start;
        color: #3D3D3D;

        font-size: 13px;
        line-height: 12px;

        padding-left: 10px;
    }
    .draws {
        font-style: normal;
        font-weight: 700;
        text-align: start;
        color: #3D3D3D;

        font-size: 13px;
        line-height: 12px;

        padding-left: 7px;
    }
    .loosesTotal {
        font-style: normal;
        font-weight: 700;
        text-align: end;
        color: #3D3D3D;

        font-size: 13px;
        line-height: 12px;
    }
}

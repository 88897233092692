@import 'styles/helpers';
@import 'tools/styles/grid';
@import 'tools/styles/adaptive';

.reports {
    .list {
        @include dGrid();

        overflow: auto;
    }
    .magic {

    }
}

@include applyResponsive320() {
    .reports {
        .list {
            @include gridColumns(1fr);

            gap: 12px;

            padding: 0 27px 0 27px;
        }
        .magic {
            display: none;
        }
    }
}

@include applyResponsive1280() {
    .reports {
        .list {
            @include gridColumns(1fr 1fr);
            @include blueScrollBar();

            gap: 10px;

            padding: 0 25px 50px 0;
        }
        .magic {
            position: absolute;
            left: -50px;
            right: -50px;
            bottom: calc(73px - 132px);

            height: 132px;

            background: #FFFFFF;
            filter: blur(18px);
        }
    }
}
